import { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { countries } from 'utils/countries';

import { useSnackbar } from 'web/contexts/SnackbarContext';

import ErrorMessage from 'components/ErrorMessage';

import Form from 'components/form/Form';
import FormIntroText from 'components/form/FormIntroText';
import TextField from 'components/form/TextField';
import SwitchField from 'components/form/SwitchField';
import DynamicPEPFields from 'components/form/DynamicPEPFields';
import AutocompleteField from 'components/form/AutocompleteField';
import SubmitButton from 'components/form/SubmitButton';

import { UPDATE_ONE_USER } from 'api/mutations/userMutations';

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	& > *:not(:last-child) {
		margin-right: 50px;
		flex: 1 1 auto;
	}

	& > *:last-child {
		width: 75%;
		flex: 1 1 75%;
	}
`;

const PEPWrapper = styled.div`
	transition: all 0.3s ease;
	padding: ${p => (p.$showfields ? '16px' : '0')};
	background: ${p =>
		p.$showfields ? p.theme.palette.background.gray : 'transparent'};
`;

export default function UserDetailsVerifyModal({
	user,
	setIsOpen,
	sessionKey,
}) {
	const { notify } = useSnackbar();

	const [errors, setErrors] = useState(null);
	const [showPepRolesFields, setShowPepRolesFields] = useState(
		user.isPoliticallyExposedPerson
	);

	const [updateOneUser, { loading, error: updateOneUserError }] = useMutation(
		UPDATE_ONE_USER,
		{
			refetchQueries: ['findUserWithUserRoles'],
		}
	);

	const values = {
		name: user.name,
		email: user.email,
		phone: user.phone,
		street_address: user.address.street_address,
		postal_code: user.address.postal_code,
		locality: user.address.locality,
		country: user.address.country,
		citizenship: user.citizenship,
		isPoliticallyExposedPerson: user.isPoliticallyExposedPerson,
		userPoliticallyExposedPersonRoles:
			user?.userPoliticallyExposedPersonRoles?.map(role => ({
				type: role.type,
				name: role.name,
				address: role.address,
			})) ?? [],
	};

	return (
		<>
			<DialogTitle id="user-verification-dialog-title">
				Bekreft din informasjon
			</DialogTitle>

			<Form
				values={values}
				isLoading={loading}
				onSubmit={async values => {
					setErrors(null);

					values.address = {
						street_address: values.street_address || undefined,
						postal_code: values.postal_code || undefined,
						locality: values.locality || undefined,
						country: values.country || undefined,
					};

					if (
						values.isPoliticallyExposedPerson &&
						values.userPoliticallyExposedPersonRoles.length === 0
					) {
						setErrors(
							'Mangler relasjoner for politisk eksponert person'
						);

						return;
					}

					if (!values.isPoliticallyExposedPerson) {
						values.userPoliticallyExposedPersonRoles = [];
					}

					try {
						const { data } = await updateOneUser({
							variables: {
								_id: user._id,
								forceUserVerificationDate: null,
								userLastVerification: new Date(),
								...values,
							},
						});

						if (data.user) {
							notify('Verifiseringen var vellykket!');
						}

						setIsOpen(false);
					} catch (error) {
						console.error(error);
					}
				}}
			>
				<DialogContent sx={{ paddingTop: 0 }}>
					<FormIntroText>
						Se over og sjekk at informasjonen om deg stemmer.
					</FormIntroText>

					<ErrorMessage errors={[errors, updateOneUserError]} />

					<TextField name="name" type="text" label="Navn" fullWidth />

					<TextField
						name="email"
						type="email"
						label="E-post"
						fullWidth
					/>

					<TextField
						name="phone"
						type="phone"
						label="Telefon"
						fullWidth
					/>

					<TextField
						name="street_address"
						label="Gateadresse"
						fullWidth
					/>

					<Row>
						<TextField
							name="postal_code"
							label="Postnummer"
							fullWidth
						/>

						<TextField name="locality" label="Sted" fullWidth />
					</Row>

					<AutocompleteField
						label="Bosatt i land"
						name="country"
						options={countries.map(country => ({
							label: country,
							value: country,
						}))}
					/>

					<AutocompleteField
						label="Statsborger i land"
						name="citizenship"
						options={countries.map(country => ({
							label: country,
							value: country,
						}))}
					/>

					<PEPWrapper $showfields={showPepRolesFields}>
						<SwitchField
							name="isPoliticallyExposedPerson"
							label="Ja, jeg er en politisk eksponert person"
							onChange={value => setShowPepRolesFields(value)}
						/>

						{showPepRolesFields && (
							<DynamicPEPFields
								name="userPoliticallyExposedPersonRoles"
								existingRelations={
									values.userPoliticallyExposedPersonRoles
								}
							/>
						)}
					</PEPWrapper>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={() => {
							setIsOpen(false);

							// Save that the user wants to hide the modal for the rest of the session
							sessionStorage.setItem(sessionKey, 'true');
						}}
					>
						Hopp over
					</Button>

					<SubmitButton variant="contained">Bekreft</SubmitButton>
				</DialogActions>
			</Form>
		</>
	);
}
