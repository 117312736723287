import { useQuery } from '@apollo/client';

import useSortOrder from 'hooks/useSortOrder';

import { useWebApp } from 'web/contexts/WebContexts';
import { useUser } from 'web/contexts/UserContext';
import { useAppFilters } from 'web/contexts/AppFiltersContext';

import ErrorMessage from 'components/ErrorMessage';
import { InlineLoading } from 'components/Loading';
import StatusIcon from 'components/StatusIcon';

import AppFilters from 'components/filters/AppFilters';

import DesktopLayout, {
	ArchiveHeader,
	ArchiveHeaderRow,
	Content,
} from 'web/components/Layout';
import SearchBar from 'web/components/NewSearchBar';
import AppActiveFilters from 'components/filters/AppActiveFilters';
import SortIcon from 'web/components/SortIcon';
import Table, { TableRow, TableCell } from 'web/components/Table';
import CreateFabButton from 'web/components/CreateFabButton';
import LoadMoreButton from 'web/components/LoadMoreButton';
import { StyledLink } from 'web/components/Link';

import TaskCalendarButton from 'web/components/TaskCalendarButton';
import TaskDeadlineEntryStatusInPeriod from 'web/components/companiesArchive/TaskDeadlineEntryStatusInPeriod';
import TaskDeadlineEntryStatusByType from 'web/components/companiesArchive/TaskDeadlineEntryStatusByType';
import QualityControlControlStatus from 'web/components/companiesArchive/QualityControlControlStatus';
import AnnualSettlementControlStatus from 'web/components/companiesArchive/AnnualSettlementControlStatus';

import RiskScore from 'web/components/companiesArchive/RiskScore';

import { PAGINATE_TENANTS } from 'api/queries/tenantQueries';

export default function CompaniesArchive() {
	const { set } = useWebApp();
	const { userHasMinRole } = useUser();
	const [{ orderBy, order }, setNewOrder] = useSortOrder('name');

	const { search, task, accountant, accountantRole, company, accountType } =
		useAppFilters();

	const {
		data: { companies = { count: 0, items: [] } } = {
			companies: { count: 0, items: [] },
		},
		loading,
		error,
		fetchMore,
	} = useQuery(PAGINATE_TENANTS, {
		variables: {
			search,
			// We need to convert the empty string we get from MUI Autocomplete to a null value.
			filter: {
				accountantId: accountant?.value || null,
				accountantRole: accountantRole?.value || null,
				company: company?.value || null,
				accountType: accountType?.value || null,
			},
			orderBy,
			order,
			offset: 0,
			limit: 50,
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all',
		notifyOnNetworkStatusChange: true,
	});

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					label: 'Bedrifter',
				},
			]}
			title="Bedrifter"
		>
			<Content>
				<ErrorMessage errors={error} />

				<>
					<ArchiveHeader>
						<ArchiveHeaderRow>
							<SearchBar label={'Søk etter bedrift'} />

							<AppFilters
								displayFilters={[
									'task',
									'month',
									'year',
									'company',
									'adminsAndAccountants',
									'accountantRole',
									'accountType',
								]}
								size="medium"
							/>
						</ArchiveHeaderRow>

						<ArchiveHeaderRow>
							<AppActiveFilters
								displayFilters={[
									'year',
									'month',
									'task',
									'adminsAndAccountants',
									'accountantRole',
									'company',
									'accountType',
								]}
							/>
						</ArchiveHeaderRow>
					</ArchiveHeader>

					<Table columns="10">
						<TableRow header>
							<TableCell
								start="1"
								end="4"
								onClick={() => setNewOrder('name')}
							>
								<span>Navn</span>

								<SortIcon
									field="name"
									orderBy={orderBy}
									order={order}
								/>
							</TableCell>

							<TableCell center>
								{task?.value ?? 'Velg en oppgave'}
							</TableCell>

							<TableCell center>Skattemelding</TableCell>

							<TableCell center>Årsregnskap</TableCell>

							<TableCell center>Kvalitetskontroll</TableCell>

							<TableCell center>Årsoppgjørkontroll</TableCell>

							<TableCell center>Hvitvasking</TableCell>

							<TableCell center>Avvik</TableCell>
						</TableRow>

						{!loading && companies?.items?.length === 0 && (
							<TableRow transparent>
								<TableCell start="1" end="-1" center>
									Ingen bedrifter ble funnet
								</TableCell>
							</TableRow>
						)}

						{!error &&
							companies?.items?.map(c => (
								<TableRow key={c._id} hover>
									<TableCell start="1" end="4">
										<TaskCalendarButton companyId={c._id} />

										<StyledLink
											to={`/bedrifter/${c._id}/`}
											onClick={() => set({ tenant: c })}
										>
											{c.name}
										</StyledLink>
									</TableCell>

									<TableCell center>
										<TaskDeadlineEntryStatusInPeriod
											companyId={c._id}
										/>
									</TableCell>

									<TableCell center>
										<TaskDeadlineEntryStatusByType
											companyId={c._id}
											type="TAX_NOTICE"
										/>
									</TableCell>

									<TableCell center>
										<TaskDeadlineEntryStatusByType
											companyId={c._id}
											type="ANNUAL_ACCOUNTS"
										/>
									</TableCell>

									<QualityControlControlStatus
										companyId={c._id}
									/>

									<AnnualSettlementControlStatus
										companyId={c._id}
										selfRevisionRequired={
											c.selfRevisionRequired
										}
										reviewedAnnualFinancialStatus={
											c.reviewedAnnualFinancialStatus
										}
									/>

									<TableCell center>
										{c.accountTypes.includes(
											'accounting'
										) && <RiskScore companyId={c._id} />}
									</TableCell>

									<TableCell
										to={`/bedrifter/${c._id}/rediger/`}
										center
									>
										<StatusIcon
											error={
												c.dataDeviations.hasDeviations
											}
											errorTitle="Bedriften har avvik!"
											successTitle="Bedriften har ingen avvik!"
										/>
									</TableCell>
								</TableRow>
							))}

						{loading && (
							<TableRow transparent>
								<TableCell start="1" end="-1">
									<InlineLoading />
								</TableCell>
							</TableRow>
						)}

						{!error &&
							!loading &&
							companies?.items?.length !== 0 && (
								<TableRow footer>
									<TableCell start="1" end="-1" right>
										Viser {companies.items.length} av{' '}
										{companies.count} bedrifter
									</TableCell>
								</TableRow>
							)}
					</Table>

					{companies && companies.count > companies.items.length && (
						<LoadMoreButton
							disabled={loading}
							onClick={() => {
								fetchMore({
									variables: {
										offset: companies.items.length,
									},
									updateQuery: (
										prev,
										{ fetchMoreResult }
									) => {
										if (
											fetchMoreResult.companies.items
												.length === 0
										) {
											return prev;
										}

										const result = {
											companies: {
												...prev.companies,
												items: [
													...prev.companies.items,
													...fetchMoreResult.companies
														.items,
												],
											},
										};

										return result;
									},
								});
							}}
						/>
					)}
				</>
			</Content>

			{userHasMinRole('admin') && <CreateFabButton to="ny/" />}
		</DesktopLayout>
	);
}
